import { type InferInput, literal, parse, union } from 'valibot';

export const registerVariantQueryKey = 'register_variant';
export const registerVariants = {
  insuranceProductDocumentRequest: 'insurance_product_document_request',
  insuranceProductConsultationRequest: 'insurance_product_consultation_request',
  directLandingFromAd: 'direct_landing_from_ad', // 広告からLPを経由せずそのまま会員登録ページに遷移した場合
  lpOkaneKenkoLifeplanning: 'lp_okane_kenko_jp__conc_lifeplan',
  lp400F: 'lp_400f', // `/partner/400f`からの流入
  lp400F2: 'lp_400f_2', // `/partner/400f_2`からの流入
  lpRakutenInhouseAdvisor: 'lp_rakuten_inhouseadvisor', // `/partner/rakuten_inhouseadvisor`からの流入
  lpSmcc: 'lp_smcc', // `/partner/smcc`からの流入
  lpZjk: 'lp_zjk', // `/partner/zjk`からの流入
  lpHokennomadoguchiTsuhan: 'lp_hokennomadoguchi_tsuhan', // `/partner/hokennomadoguchi_tsuhan`からの流入
  lpKiraboshi: 'lp_kiraboshi', // `/partner/kiraboshi`からの流入
  lpMoneyForward: 'lp_moneyforward', // `/partner/moneyforward`からの流入
} as const;

const registerVariantSchema = union(
  Object.values(registerVariants).map((variant) => literal(variant))
);

export type RegisterVariant = InferInput<typeof registerVariantSchema>;

export const useRegisterValiant = () => {
  const route = useRoute();
  const registerVariantQueryValue = getStringQuery(
    route.query[registerVariantQueryKey]
  );

  const registerVariant = (() => {
    try {
      return parse(registerVariantSchema, registerVariantQueryValue);
    } catch {
      return null;
    }
  })();

  return {
    registerVariant,
  };
};
